html,
body {
    padding: 0;
    margin: 0;
    width: 100%;
    background-color: #EBECF4 !important;
    overflow: auto; /* Abilita lo scrolling verticale */
}

.page-wrapper {
    position: relative;
    overflow: hidden; /* Nasconde il contenuto oltre il viewport */
}

.theme-dark {
    fill: var(--bs-dark);
}

.slider-container {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1; /* Posto sotto l'header */
}

.header-container {
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 2; /* Sopra lo slider */
}

.content-container {
    position: relative;
    z-index: 3; /* Sopra tutto, incluso slider e header */
}

.main,
.footer {
    position: relative;
}
